$black: #000000;
$primary: #1c8267;
$white: #ffffff;
$main-bg: #86d6d3;
$count-color: #0146b9;
$graphic-bg: #ebf0ef;
$rectangle-border: #979797;
$rectangle-bg: #d8d8d8;
$filter-box-color: #c6d4d1;
$table-box-bg: #f1f5f4;
$text-color: #787878;
$table-box-border: #c6d4d1;
$danger: #d0021b;
