.mr-100 {
	margin-right: 100px !important;
}

.ml-50 {
	margin-left: 50px !important;
}
.ml-40 {
	margin-left: 40px !important;
}

.mt-50 {
	margin-top: 50px !important;
}
.ml-10 {
	margin-left: 10px !important;
}
.mt-10 {
	margin-top: 10px !important;
}
.mt-20 {
	margin-top: 20px !important;
}

.mt-60 {
	margin-top:60px !important;
}

.ml-120 {
	margin-left: 120px !important;
}

.mt-100 {
	margin-top: 100px !important;
}

.mt-110 {
	margin-top:110px !important;
}

.mb-50 {
	margin-bottom: 100px !important;
}

.m50 {
	margin: 50px !important;
}

.p-20 {
	padding: 20px !important;
}

.pl-50 {
	padding-left: 50px !important;
}
